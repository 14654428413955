import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {  
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
  NgxScannerQrcodeService,
  NgxScannerQrcodeComponent,
  ScannerQRCodeSelectedFiles, 
} from 'ngx-scanner-qrcode';
import { IndoorService } from '@app/_services/indoor.service'; 
import Swal from 'sweetalert2';
import { DneService } from '@app/_services/dne/dne.service';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { LoginService } from '@app/_services/login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-qrcode',
  templateUrl: './modal-qrcode.component.html',
  styleUrls: ['./modal-qrcode.component.scss']
})
export class ModalQrcodeComponent implements OnInit, AfterViewInit {

  @Input() tipoLeitura: string;

  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth,
        height: window.innerHeight - 50
      },
    },
  };

  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];

  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  public percentage = 80;
  public quality = 100;
  permissaoConcedida: string = "Sem resposta";
  restaurante: DetalhesLoja;

  constructor(
    public activeModal: NgbActiveModal,
    private qrcode: NgxScannerQrcodeService,
    public indoorService: IndoorService,
    public dneService: DneService,
    private loginService: LoginService,
    private toastr: ToastrService,
  ){
    this.restaurante = this.loginService.lojaLogada;
  }

  ngOnInit(): void {
    this.localizacao();
  }

  ngAfterViewInit(): void {
    this.action.isReady.subscribe((res: any) => {
      this.handle(this.action, 'start');
    });
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    this.handle(this.action, 'pause');

    if(this.tipoLeitura == "Comanda"){
      this.indoorService.comanda = e[0].value;
      this.activeModal.close();
      this.toastr.success(`Comanda ${ e[0].value } vinculada com sucesso.`, "Não foi possível concluir a operação", {
        positionClass: 'toast-bottom-left',
      });
    } else {
      let index = e[0].value.indexOf("Mesa")
      let novaString = e[0].value.slice(index + 4);

      this.indoorService.numeroMesa = novaString;     
      this.indoorService.indoor = true;
      this.activeModal.close();
    }
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      //const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe();
    } else {
      action[fn]().subscribe();
    }
  }

  fecharModal() {
    this.activeModal.close();
  }

  localizacao() {
    navigator.geolocation.getCurrentPosition(position => {
      var localizacao = position.coords.latitude + " " + position.coords.longitude;

      this.dneService.calculaDistanciaEntreEnderecos(localizacao, this.restaurante.EnderecoCompleto, false).subscribe((distancia) => {
        if(distancia.Distancia > 5000){
          this.indoorService.distanciaPermitida = false;
        } else {
          this.indoorService.distanciaPermitida = true;
        }
      });

    })
  }

  get permissao() {

    if (this.permissaoConcedida == "Sem resposta"){
      navigator.mediaDevices.getUserMedia({video:{width:500,height:500}}).then((response) => {
        this.permissaoConcedida = "Permitido";
      }).catch(err => {
        this.permissaoConcedida = "Negado";
        this.handle(this.action, 'pause');
        this.activeModal.close();
        Swal.fire({
            title: 'Permissão negada!',
            text: 'Ops! Não conseguimos acessar sua câmera para escanear o QR Code e vincular sua comanda. Por favor, ajuste as configurações de permissão do navegador para continuar.',
            icon: 'error',
            confirmButtonText: 'Ok',
        }).then(() => {
        })
      })
    }
    
    return this.permissaoConcedida;
  }
}
