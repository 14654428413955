<div *ngIf="!modalAberto" class="menu_fixed">
    <button class="btn-icon" [routerLink]="'indoor/Mesa' + numeroMesa">
        <div>
            <mat-icon class="material-icons-outlined">
                home
            </mat-icon>
        </div>
        <span>
            Home
        </span>
    </button>
    <button class="btn-icon" (click)="verSacola()">
        <div>
            <mat-icon *ngIf="quantidadeItensSacola != 0" class="material-icons-outlined color-9-badge" [matBadge]="quantidadeItensSacola">
                shopping_basket
            </mat-icon>
            <mat-icon *ngIf="quantidadeItensSacola == 0" class="material-icons-outlined">
                shopping_basket
            </mat-icon>
        </div>
        <span>
            Sacola
        </span>
    </button>
    <button class="btn-icon" [routerLink]="'indoor/Mesa' + numeroMesa + '/comanda'">
        <div>
            <mat-icon class="material-icons-outlined">
                receipt
            </mat-icon>
        </div>
        <span>
            Pedidos
        </span>
    </button>
    <button hidden class="btn-icon" routerLink="garcom">
        <div>
            <mat-icon class="material-icons-outlined">
                notifications_active
            </mat-icon>
        </div>
        <span>
            Garçom
        </span>
    </button>
</div>
  