<!-- /col -->
<div class="box_order" #resumoPedidoIndoor>
    <div class="head" [ngClass]="!indoor ? 'margem-titulo' : ''">
      <h3>Resumo do pedido</h3>
    </div>
  
    <!-- /head -->
    <div class="main" style="color: black;">
        <div class="modal_body">
            <ul class="clearfix">
                <li *ngFor="let item of itensPedidos">
                    <div *ngIf="!item.variacao" class="item">
                        <figure style="margin: 0; position: relative;">
                            <img [src]="imagemURL(item) ? imagemURL(item) : 'https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/menu-thumb-placeholder.jpg'" data-src="img/menu-thumb-1.jpg" alt="" width="50" height="50" class="lazy">
                        </figure>
                        <div class="infos-itens">
                            <span style="width: 100%;">
                                {{ item.MercadoriaNomeCurto }}
                            </span>
                            <span class="preco">
                                {{ (item.Total | currency:'BRL')}}
                            </span>
                        </div>
                        <div class="quantidade">
                            <span>{{ item.Quantidade }}</span>
                        </div>
                    </div>
        
                    <div *ngIf="item.variacao">
                        <figure style="margin: 0;">
                            <img [src]="imagemURL(item) ? imagemURL(item) : 'https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/menu-thumb-placeholder.jpg'" data-src="img/menu-thumb-1.jpg" alt="" width="50" height="50" class="lazy">
                        </figure>
                        <div class="infos-itens">
                            <span style="width: 100%;">
                                {{ 
                                    item.mercadoria.MercadoriaNomeCurto + " - " + 
                                    item.variacao.Componente1 + " " + 
                                    item.variacao.Componente2
                                }}
                            </span>
                            <span class="preco">
                                {{ 
                                    (item.Total | currency:'BRL' ) 
                                }}
                            </span>
                        </div>
                        <div class="quantidade">
                            <span>{{ item.Quantidade }}</span>
                        </div>
        
                        <div *ngIf="item.variacao.DescricaoComponente1" style="font-size: 12px; margin-left: 5px;">
                            <div style="width: 75%; display: inline-block;">
                                {{
                                    item.DescricaoComponente1
                                }}
                            </div>
                        </div>
        
                        <div *ngIf="item.variacao.DescricaoComponente2" style="font-size: 12px; margin-left: 5px;">
                            <div style="width: 75%; display: inline-block;">
                                {{
                                    item.DescricaoComponente2
                                }}
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="item.preparos" >
                        <div *ngFor="let preparo of item.preparos" style="font-size: 12px; margin-left: 5px;">
                            <div style="width: 75%; display: inline-block;">{{preparo.Nome}}</div>
                            <span>
                                {{ 
                                    (preparo.PrecoVenda > 0 ? '+ ' + (preparo.PrecoVenda * item.quantidade | currency:'BRL') : '')
                                }}
                            </span>
                        </div>
                    </div>
        
                    <div style="font-size: 12px; margin-left: 5px;">{{ item.observacao }}</div>
                </li>
            </ul>
        </div>
  
        <div class="modal_footer">
            <ul class="clearfix" style="color: black;">
                <li>Subtotal<span>{{ (subtotalPedido | currency:'BRL') }}</span></li>
    
                <li class="total">
                    Total
                    <span>
                        {{ (valorTotalPedido | currency:'BRL') }}
                    </span>
                </li>
            </ul>
    
            <form [formGroup]="form">
                <div style="margin: 10px 0px;" class="form-group">
                    <label>CPF/CNPJ na nota</label>
                    <input formControlName="CPFCNPJ" class="form-control" placeholder="CPF/CNPJ" [mask]="cpfCnpjMask"
                    [validation]="false">
                    <mat-slide-toggle formControlName="cpfCnpjNaNota"></mat-slide-toggle>
                </div>
            </form>
    
            <!-- /dropdown -->
            <div class="btn_1_mobile">
                <button (click)="fecharConta()" class="btn_1 full-width mb_5">
                    Fechar conta
                </button>
            </div>
        </div>
    </div>
</div>
  