import { DBConfig } from "ngx-indexed-db";

export function migrationFactory() {
  // The animal table was added with version 2 but none of the existing tables or data needed
  // to be modified so a migrator for that version is not included.
  return {
    1: (db, transaction) => {
      const store = transaction.objectStore('itensPedido');
    },
    2: (db, transaction) => {
      const store = transaction.objectStore('itensPedido');
      const endereco = transaction.objectStore('endereco');
    },
    3: (db, transaction) => {
      const mesa = transaction.objectStore('mesa');
    },
    4: (db, transaction) => {
      const comanda = transaction.objectStore('comanda');
    }
  };
}

export const dbConfig: DBConfig = {
  name: 'NoxMob',
  version: 1,
  objectStoresMeta: [{
    store: 'itensPedido',
    storeConfig: { keyPath: 'codigoItem', autoIncrement: true },
    storeSchema: [
      { name: 'item', keypath: 'item', options: { unique: false } },
    ]
  },
  {
    store: 'endereco',
    storeConfig: { keyPath: 'codigoEndereco', autoIncrement: true },
    storeSchema: [
      { name: 'item', keypath: 'item', options: { unique: false } },
    ]
  },
  {
    store: 'comanda',
    storeConfig: { keyPath: 'comanda', autoIncrement: true},
    storeSchema: [
      { name: 'item', keypath: 'item', options: { unique: false } },
    ]
  },
  {
    store: 'mesa',
    storeConfig: { keyPath: 'mesa', autoIncrement: true},
    storeSchema: [
      { name: 'item', keypath: 'item', options: { unique: false } },
    ]
  }],

  migrationFactory
};
