<div id="sign-in-dialog" class="cadastro_endereco" id="modal-dialog">
    
    <div class="modal_header" id="modal_header" style="margin-bottom: 0;" [ngClass]="{ 'zerar_padding_right': removePaddingMobile }">
      <mat-icon id="icon_header" class="icon-voltar material-icons-outlined" (click)="voltarModal(stepper)" [hidden]="btn_voltar">chevron_left</mat-icon>
      <h3 class="modal_titulo">{{ tituloModal }}</h3>
      <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
        <mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
      </button>
    </div>
  
    <div class="modal_body" id="modal_body" [ngClass]="{ 'zerar_padding': removePadding }">
      <mat-stepper linear #stepper (selectionChange)="onStepChange($event)">
        
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup"  style="margin-top: 40px;">
            <div *ngIf="restaurante.HabilitarEntrega" class="col-md-12 card-endereco">
              <a id="entrega" style="cursor: pointer; height: 140px" class="menu_item modal_dialog" [ngClass]="{ 'selecionado': entregaSelecionada }" (click)="selecionarEntrega(stepper)">
                <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/take-away-cuate.svg" alt="À esquerda, um celular com um mapa na tela, marcando os endereços de origem e destino, com um traçado indicando a rota entre eles. No meio, um círculo vazado indica uma parada. Abaixo, a indicação da distância a ser percorrida. À direita, uma pessoa em uma moto." width="150px">
                <h3>Entrega</h3>
                <strong>Quero que entregue em meu endereço</strong>
              </a>
            </div>
            <div *ngIf="restaurante.HabilitarPraRetirar" class="col-md-12 card-endereco" (click)="selecionarRetidada()">
              <a style="cursor: pointer; height: 140px" class="menu_item modal_dialog">
                <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/enderecos.svg" alt="Pessoa de óculos segurando um mapa, ao fundo vários prédios, sendo um deles marcado com um pin indicando o endereço de destino." width="150px">
                <h3>Retirada</h3>
                <strong>Quero ir buscar meu pedido</strong>
                <p *ngIf="restaurante">{{ restaurante.EnderecoCompleto }}</p>
              </a>
            </div>
            <div *ngIf="!this.usuarioLogado && restaurante.HabilitarEntrega" class="col-md-12 login">
              <div style="text-align: left;">
                <h3>Já possui um endereço cadastrado?</h3>
                <button (click)="abrirModalLogin()" class="btn_1 full-width">Efetuar login</button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="secondFormGroup">
          <form [formGroup]="secondFormGroup">
            <div style="text-align: center;">
              <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/endereco-mapa-2.svg" alt="Quadro com um mapa, nele duas marcações de endereço e um traçado entre elas. À direita, uma pessoa posiciona uma das marcações de endereço." width="275px">
            </div>
          
            <label *ngIf="enderecosCadastrados">Cadastrar novo endereço</label>
            <input #mapSearchField class="form-control google_search" placeholder="Buscar endereço" type="text">
      
            <div *ngIf="this.formattedAdress" class="endereco" id="novo_endereco" [ngClass]="{ 'selecionado': enderecoSelecionado }" (click)="selecionarNovoEndereco(stepper)">
              <mat-icon class="icon text-muted material-icons-outlined">place</mat-icon>
      
              <div style="display: inline-block; width: 100%;">
                <div>
                  {{ formattedAdress }}
                </div>
              </div>
            </div>

            <div *ngIf="localizacao" class="endereco" id="localizacao" [ngClass]="{ 'selecionado': enderecoSelecionado }" (click)="selecionarLocalizacao(stepper)">
              <mat-icon class="icon text-muted material-icons-outlined">my_location</mat-icon>
      
              <div style="display: inline-block; width: 100%;">
                <div>
                  Usar localização atual
                </div>
              </div>
            </div>

            <div *ngFor="let endereco of enderecosCadastrados" [id]="'endereco_' + endereco.Codigo" [ngClass]="{ 'editar': enderecoEditar?.Codigo == endereco.Codigo }" class="endereco">
              <mat-icon class="icon material-icons-outlined icon-favorito" *ngIf="endereco.Principal">favorite</mat-icon>
              <mat-icon class="icon material-icons-outlined icon-favorito" *ngIf="!endereco.Principal" (click)="marcarEnderecoPrincipal(endereco)">favorite_border</mat-icon>
      
              <div style="display: inline-block; width: 100%;" (click)="selecionarEndereco(endereco)">
                <div>
                  {{ endereco.Tipo }}
                </div>
                <div style="font-weight: 500; font-size: 15px;">
                  {{ endereco.Rua.trim() }}, {{ endereco.Numero }},
                  {{ endereco.Bairro }}, {{ endereco.Municipio }}
                </div>
                <div>
                  {{ endereco.Complemento }}
                </div>
              </div>
      
              <div style="display: inline-flex; float: right;">
                <div mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                </div>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item (change)="editar(endereco)">
                    <label class="container_check" style="margin-right: 10px;">Editar
                      <input type="checkbox" id="'endereco_editar_' + endereco.Codigo" [checked]="enderecoEditar?.Codigo == endereco.Codigo">
                      <span class="checkmark"></span>
                    </label>
                  </div> 
                  <div mat-menu-item class="menu-item" style="padding-left: 10px;" (click)="removeEndereco(endereco)">
                    <mat-icon class="material-icons-outlined icon-delete" style="margin-right: 5px;">delete</mat-icon>
                    <span class="box1" style="cursor: pointer; margin-right: 10px;">
                      Excluir
                    </span>
                  </div>
                </mat-menu>
              </div>
              
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="thirdFormGroup">
          <form [formGroup]="thirdFormGroup">

            <google-map 
              #map
              width="100%"
              height="550px"
              [center] = "center"
              [options]="mapOptions"
            >
              <map-marker #marker [position]="center" [options]="markerOptions"></map-marker>
            </google-map>
                
            <div class="row_navegacao">
              <button class="btn_1" mat-button matStepperNext (click)="confirmarLocalizacao()">Confirmar localização</button>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="fourthFormGroup">
          <form [formGroup]="fourthFormGroup" class="confirmar_endereco">

            <div style="text-align: center;">
              <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/endereco-mapa.svg" alt="Pessoa segurando a lateral de um mapa à esquerda. No mapa, duas marcações, uma indicando o destino inicial e outra o destino final. À direita, outra pessoa apontando para o destino final no mapa." width="260px">
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-8 margin_bottom">
                  <label>Rua</label>
                  <input #rua class="form-control" formControlName="Rua">
                </div>
                <div class="col-md-4">
                  <label>Número</label>
                  <input #numero type="number" class="form-control" placeholder="Digite o número"  formControlName="Numero">
                </div>
              </div>
            </div>
        
            <div class="form-group">
              <div class="row">
                <div class="col-md-8 margin_bottom">
                  <label>Bairro</label>
                  <input #bairro class="form-control" formControlName="Bairro">
                </div>
        
                <div class="col-md-4">
                  <div class="custom_select clearfix cleareable">
                    <label>Tipo</label>
                    <select class="wide form-control" formControlName="CodTipo">
                      <option value="" disabled selected>Selecione um tipo</option>
                      <option *ngFor="let tipo of tipos" [value]="tipo.Codigo">{{ tipo.Nome }}</option>
                    </select>
                    <mat-icon class="material-icons-outlined icon-limpar" style="cursor: pointer;" (click)="removerTipo()">close</mat-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-8 margin_bottom">
                  <label>Complemento</label>
                  <input class="form-control" placeholder="Apartamento / Bloco / Casa" formControlName="Complemento">
                </div>
              </div>
            </div>
        
            <div class="form-group margin_bottom">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <label class="container_check">Usar esse endereço por padrão
                    <input type="checkbox" formControlName="Principal" (change)="favoritar()">
                    <mat-icon class="icon_favorito material-icons-outlined" [hidden]="favorito">favorite_border</mat-icon>
                    <mat-icon class="icon_favorito material-icons-outlined" [hidden]="!favorito">favorite</mat-icon>
                  </label>
                </div>
              </div>
            </div>
            <div class="row_navegacao">
              <button class="btn_1" mat-button (click)="salvar()">Pronto</button>
            </div>
          </form>
        </mat-step>

      </mat-stepper>

    </div>
  
  </div>