import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chamar-garcom',
  templateUrl: './chamar-garcom.component.html',
  styleUrls: ['./chamar-garcom.component.scss']
})
export class ChamarGarcomComponent implements OnInit {

    constructor(
    ) { }

    ngOnInit(): void { 
    }
}

